.row-vin-padding12 {
  padding: 0 12px;
}
.col-vin-alignself {
  align-self: center;
}
.color-red {
  color: #c2181d;
}
.img-border {
  border: 2px solid gray;
}
