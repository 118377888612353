/*
body {
  background: #000000;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 30px;
}
*/

.fade {
  opacity: 1;
}

.sponsor-pending-card {
  background-color: yellow;
}

.loading-center > div {
  margin: 0 auto;
}
